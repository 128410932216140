@media (min-width: 1300px) {
    .container{
        max-width: 1300px;
    }
}
.init {
    padding-left: 0;
    .initTable {
        font-size: 12px;
        .initHeader {text-align: center;}
        .initTd {padding: 5px;}
    }
}
.minMax {
    padding-right: 0;
    .minMaxTable {
        font-size: 12px;
        .minMaxHeader {text-align: center;}
        .minMaxTd {
            padding: 0;
            .minMaxSpan {padding: 5px;align-self: center;}
        }
    }
}
.sellRates {
    padding-right: 0;
    .sellTable {
        font-size: 12px;
        .sellHeader {text-align: center;
            .sellTh {width: 80px;}
        }
        .sellTd {
            padding: 0;
            max-width: 115px;
            .input-group {
                padding-bottom: 0;
                .input-group-prepend .input-group-text {border-radius: 0;border: 0;}
                .form-control {
                    border-radius: 0;
                    font-size: 12px;
                    border-width: 2px;
                    height: auto;
                    align-self: center;
                }
            }
        }
    }
}
.margin {
    padding-right: 0;
    padding-left: 0;
    .marginTable {
        font-size: 12px;
        .marginHeader {
            text-align: center;
        }
        .tr {
            text-align: center;
            .marginTd {
                padding: 0;
                max-width: 115px;
                .input-group {
                    padding-bottom: 0;
                    .input-group-prepend .input-group-text {border-radius: 0;border: 0;font-size: 12px;}
                    .form-control {border: 0;background-color: white;font-size: 12px;}
                }
            }
            .profTd {padding: 5px;max-width: 115px;}
        }
    }
}
.buyTable {
    font-size: 12px;
    height: 400px;
    border-collapse: inherit;
    border-spacing: 1px;
    .buyHeader {
        text-align: center;
        .th {position: sticky;top: 0;}
        .secondTh {position: sticky;top: 30px;}
    }
    .buyBody {
        text-align: center;
        .paddingTd {padding: 5px;}
        .buyTd {padding-bottom: 0;white-space: nowrap;}
    }
}
.buttonDiv {padding-right: 0;padding-left: 0;padding-bottom: 15px;}