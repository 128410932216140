#auth-page-wrapper {
  background-image: url("../img/signin_bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;;
}

#auth-card {
  width: 300px;

  #auth-form-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
  
    #auth-form-brand-logo {
      width: 100%;
    }
  }

  #auth-button {
    width: 100%;
    margin-top: 0.8rem;
  }

  #forgot-button {
    padding: 0;
    margin-top: 0.8rem;
  }
}

.spinner-border {
  width: 21px;
  height: 21px;
}



