.action-wrapper{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    button{margin-left: 10px;}
}

.confirm-modal{
    white-space: normal;
}