.modal-table-wrapper {
    &:last-child {margin-bottom: 0.6rem}
    .table-label {
      margin: 0;
    }

      table {
        position: relative;
        margin-bottom: 0;
        width: 100%;

        thead {
          position: sticky;
          top: 0;
          background-color: #fff;
        }

        th {
          font-size: 0.8rem;
          white-space: nowrap;
        }

        .date-td{
          white-space: nowrap;
        }

        td {
          font-size: 0.8rem;
          padding: 0.4rem;
        }
      }
}

.h-2 {
  height: 0.5rem;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}
