#pass-page-wrapper {
  background-image: url("../img/signin_bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;;
}

.pass-input-wrapper {
  height: 75px;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;

  label {
    font-size: 0.9rem;
    text-align: left;
    margin-bottom: 2px;
  }

  .pass-input-errors {
    font-size: 0.6rem;
    color: red;
  }

  .form-control.has-error {
    border: 1px solid red;
  
  }
}

#pass-card {
  width: 300px;

  #pass-form-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;

    #pass-form-p {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
  
  #hr-m {
    border: solid 1px #006241;
  }

  #pass-button {
    width: 100%;
    margin-top: 0.8rem;
  }

  #pass-back-button {
    width: 100%;
    margin-top: 0.8rem;
  }
}

.spinner-border {
  width: 21px;
  height: 21px;
}