#sell-rate-page-wrapper {
  height: 100%;
  width: auto;

  .new-rate-button-group {
    margin: 0.6rem 0;
    button {
      margin-right: 6px;
    }
  }

  .t-table-wrapper {
    margin-top: 0.6rem;
    &:last-child {margin-bottom: 0.6rem}
    .table-label {
      margin: 0;
    }

    .table-overflow-wrapper {
      overflow-x: auto;
      margin-top: 0.5rem;
      table {
        position: relative;
        margin-bottom: 0;
        width: auto !important;

        th {
          position: sticky;
          top: 0;
          font-size: 0.8rem;
          white-space: nowrap
        }

        tr {
          &.expiring {
            background-color: #fbfb65;
          }

          &.expired {
            background-color: #ff8f8f;
          }
        }

        .date-td{
          white-space: nowrap;
        }

        td {
          font-size: 0.8rem;
          padding: 0.4rem;

          .form-row-action-buttons {
            button {
              font-size: 0.8rem;
              padding: 0 4px;
            }
          }
          .table-row-action-buttons {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            button {
              font-size: 0.8rem;
              padding: 0 4px;
              margin-right: 4px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
    .ocean-rate-wrapper, .drayage-rate-wrapper {
      margin-bottom: 1.8rem;
      .total-label {
        width: 150px;
      }
    }
  }

  .client-info-section {
    margin-bottom: 2rem;
    background-color: rgba(0, 98, 65, 0.21);
    padding: 1rem;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-end;

    > * {
      margin-right: 6px;
    }

    .rate-sheet-actions{
      .action-wrapper{
        button{ margin: 0 10px;}
      }
    }
  }

  .date-picker-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    // margin-bottom: 2rem;
    span {vertical-align: text-bottom;}
  }
}

.red-text {
  color: red;
}

#ocean-rate-form, #dray-rate-form {
  display: inline-block;
  padding: 1rem;
  background-color: rgba(0, 98, 65, 0.21);
}

.modal-close-btn {
  margin-bottom: 12px;
}

.snapshot-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin-right: 12px;
  }
}