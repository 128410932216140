.wrapper{
    textarea{ 
        width:100%; 
        min-height: 25vh;}

    span{
        margin-top: 1rem;
        button {
            width: 45%;
        }
    }

}