#log-table{
    height: 100%;
    max-height: 500px;
    overflow: auto;
    margin-top: 25px;
    table {
        font-size: .9rem;
        margin-bottom: 0;
        position: relative;
        th { 
            position: sticky; 
            top: 0;
            white-space: nowrap;
        }
        td { padding: 0.4rem; }
        
        .fa.fa-calendar { 
            cursor: pointer;  
            color: #0056b3;
        }
    }
    a {
        margin-left: 10px;
        cursor: pointer;
    }
    i {
        margin-left: 10px;
    }
    .no-data{
        text-align: center;
    }

    .action{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        button{ border: none; }

        .save{ color:#008000}
        .cancel{ color:#FF0000}
    }
    .data-padding{
        align-self: center;
        padding-left: 5px;
    }

}

#pss-form {
    .form-row { margin: 10px 0px; }
}

.dates{
    label{
        margin-right: 10px;
    }
    .pssDatePicker {
        padding-right: 10px;
    }
}

button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
    color: #000000;
}

.react-date-picker__wrapper {
    border-radius: 5px;
}

.filter-select-wrapper{
    margin-right: 10px;
}
