/* import only the necessary Bootstrap files */
@import "node_modules/bootstrap/scss/_functions";
@import "node_modules/bootstrap/scss/_variables";
@import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans:700&display=swap');
/* -------begin customization-------- */
/* change the primary theme color to red */
$theme-colors: (primary: #006241);

/* change the base font family to Open Sans */
$font-family-base: 'Open Sans', sans-serif;

$enable-rounded: false;
/* -------end customization-------- */
/* finally, import Bootstrap to set the changes! */
@import "node_modules/bootstrap/scss/bootstrap";
