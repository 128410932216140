main {
  height: 100%;
}

.rate-pusher {
  height: 150px;
  width: 500px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.buy-rate-page-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  padding-left: 1.6rem;

  .buy-rate-search-wrapper {
    padding: 1rem;

    background-color: rgba(0, 98, 65, 0.21);
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;

    .buy-rate-search-form {
      margin-right: 8px;
    }
  }

  .buy-rate-table-wrapper {
    width: 100%;
    overflow-x: auto;
  }

  .filter-wrapper {
    position: absolute;
    top: 0px;
    left: -1rem;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    .filter-panel-toggle {
      min-height: 125px;
      background-color: #006241;
      cursor: pointer;
      user-select: none; /* Standard */
      text-transform: uppercase;
      writing-mode: vertical-rl;
      text-orientation: sideways;
      color: white;
      padding: 0.3rem;

      box-shadow: 4px 4px 4px rgba(0,0,0, 0.3);

      &:hover {
        background-color: #005533;
      }
    }

    .filter-panel {
      min-height: 500px;
      width: 300px;
      background-color: white;
      border: 1px solid rgba(180, 180, 180, 0.3);
      box-shadow: 4px 4px 4px rgba(0,0,0, 0.3);
      z-index: 100;

      .panel-header {
        height: 50px;
        width: 100%;

        margin-bottom: 10px;
        box-shadow: 0 1px 1px rgba(0,0,0, 0.3);

        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        .header-text {
          text-transform: uppercase;
          margin: 0;
        }

      }

      .filter-group {
        padding: 6px;

        .checkbox-row {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;

          input {
            // line-height: 1.5;
            margin: 0 6px 0 6px;
          }

          p, label {
            margin: 0;
          }
        }

        ul {
          list-style: none;
          padding: 0;

        }
      }
    }
  }
}

.notes-input {
  textarea {
    display: block;
  }
}
