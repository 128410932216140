.prompt-container{
  .prompt-body{
    display: flex;
    justify-content: center;
    width: 400px;
    white-space: normal;
  }
  .prompt-actions{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    margin-top: 20px;

    button{
      width: 45%;
    }
  }
}