@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

html, body {
  height: 100%;
  font-family: 'Open Sans', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > #root > div {
  min-height: 100vh;
}

#modal-root {
  position: relative;
  z-index: 999;
}

.no-padding {
  padding: 0 !important;
}

.form-input-group {
  .input-errors {
    font-size: 0.6rem;
    color: red;
  }

  .form-control.has-error {
    border: 1px solid red;

  }
  label.has-error {
    color: red;
  }
}

// Hide text input 'X' clear button on IE and Edge browsers
::-ms-clear {
  display: none;
}

.date-picker-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  // margin-bottom: 2rem;
  span {vertical-align: text-bottom;}

  .react-date-picker__wrapper{
    border: 1px solid #ced4da;
    background-color: white;
    border-radius: 0px;
  }
}

.form-column {
  display: flex;
  flex-direction: column;

  > * {
    margin: 0;
    margin-bottom: 12px;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center
  }

  &.justify-end {
    justify-content: end;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center
  }

  &.align-end {
    align-items: end;
  }
}

.form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > * {
    margin: 0;
    margin-right: 8px;
  }

  &.justify-start {
    justify-content: flex-start;
  }

  &.justify-center {
    justify-content: center
  }

  &.justify-end {
    justify-content: end;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center
  }

  &.align-end {
    align-items: end;
  }
}

.button-row {
  > * {
    margin: 0;
    margin-right: 8px;
  }
}

.modal-dialog{
  width: auto;
  min-width: 900px;
}