.port-select-wrapper {
  position: relative;
  max-width: 325px;

  label, input {
    display: block;
  }

  label.has-error {
    color: red;
  }
  .form-control.has-error {
    border: 1px solid red;

  }

  ul {
    z-index: 90;
    position: absolute;
    top: 75px;

    max-height: 150px;
    min-width: 100%;

    padding: 0;
    list-style: none;
    background-color: white;

    border: 1px solid rgba(220, 220, 220, 0.5);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);

    overflow-y: auto;

    li {
      cursor: pointer;
      display: block;

      padding: 8px;

      border-bottom: 1px solid rgba(220, 220, 220, 0.5);

      &:hover {
        background-color: rgba(220, 220, 220, 0.5);;
      }

      &.highlighted {
        background-color: rgba(220, 220, 220, 0.5);;
      }
    }
  }
}