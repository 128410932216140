.tabs {
    margin-bottom: 1.5rem;
}


.active-tab {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    cursor: pointer;
    p {
        color: #006241;
        text-decoration: none;
        font-size: 1.5rem;
        margin: 0px;
    }
}

.tab {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    cursor: pointer;
    p {
        color: gray;
        text-decoration: none;
        font-size: 1.5rem;
        margin: 0px;
    }
}