#temp {
    margin-top: 20px;
}
#ec-group {
    display: flex;
    width: 100%;
    padding-bottom: 0;
}
#gri-form {
    background-color: white;
}
.btn-remove {
    border: none;
    background-color: transparent; 
    color:#FF0000;
    font-size: 20px;
}
.btn-remove[disabled]{color: #808080;}
.btn-add {
    border: none;
    background-color: transparent; 
    color:#006241;
    font-size: 20px;
}
.btn-add[disabled]{color: #808080;}
#label-incl{
    margin-right: 2px;
}