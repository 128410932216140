.client-profile{
    max-width: 1500px;
    
    .container {
        max-width: 100%;
        margin: 0;
        padding: 0px;
    }

    img { 
        max-width: 130px; 
        max-height: 85px;
        margin: 10px;
    }
}

.errorList{
    color:#FF0000;
}

.general{
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    label{
        font-weight: bold;
    }
    textarea{
        margin-bottom: 10px;
    }
}

.group{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between; 
    padding-bottom: 10px;
    .form-control-file {
        height: fit-content;
        width: 108px;
        margin-right: auto;
    }
    input{
        margin-left: 10px;
        width:50%;
    }
    input[type=file] {color: transparent;}
    input[type=file]::-webkit-file-upload-button {cursor: pointer;}
    select{
        width: 50%;
    }
    textarea{
        width: 50%;
    }
}

.radio-parent{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 10px;
}

.radio-group{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 50%;
    .form-last-button {margin-right: 0;}
}

.addUpdate{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
}

img { max-width: 150px; }

.btn:disabled{
    cursor: default;
}

.errors{
    display: flex;
    justify-content: flex-end;
    color: #FF0000;
}

.contact-wrapper {
    .add-btn{
        color:#006241;
        text-align: center;
        border: none;
        background-color: transparent;
    }

    .add-btn[disabled]{ color: #808080; }

    .card-body{padding: 0;}
    
    .contacts-table{
        padding: 0;
        max-height: 250px;
        overflow: auto;
    
        button{ border: none; }
        
        .table{
            position: relative;
            margin-bottom: 0;
            .newEntry{
                th{
                    top: 50px;
                }
            }

            th{
                position: sticky;
                top: 0;
                width: 25%;
            }
    
            td{ word-break: break-all; }
    
            .no-contacts{ text-align: center; }
    
            .action-cell{
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-evenly;
                
                button{ background-color: transparent; }
    
                .trash-cancel-btn{ color:#FF0000; }
            
                .trash-cancel-btn[disabled]{ color: #808080; }
            
                .save-btn{ color:#006241; }

                .save-btn[disabled]{ color: #808080; }
            
                .edit-btn{ color:#FFA500; }

                .edit-btn[disabled]{ color: #808080; }
            }
        }
    }
}


.note-wrapper{

    .add-btn{
        color:#006241;
        text-align: center;
        border: none;
        background-color: transparent;
    }

    .add-btn[disabled]{ color: #808080; }

    .card-body{padding: 0;}

    .notes-table{
        padding: 0px;
        overflow: auto;
    
        button{ border: none; }
        
        .table{
            position: relative;
            margin: 0;
            textarea{ width: 100%; }
            th{ 
                position: sticky;
                top: 0;
            }
    
            td{
                word-break: break-all;
    
            }
            
            .date-col{ width: 10%; }
    
            .comm-col{ width: 70%; }
    
            .act-col{ width: 5%; }
    
            .no-notes{ text-align: center; }
    
            .action-cell{
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-evenly;
                
                button{ background-color: transparent; }
    
                .trash-cancel-btn{ color:#FF0000; }
            
                .trash-cancel-btn[disabled]{ color: #808080; }
            
                .save-btn{ color:#006241; }

                .save-btn[disabled]{ color: #808080; }
            
                .edit-btn{ color:#FFA500; }

                .edit-btn[disabled]{ color: #808080; }
            }
        }
    }
}


.confirm-modal{
    p { text-align: center; }
    
    span{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
    }
}

.client-action-btns{
    width: 40%;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 12px;
    margin: 0 10px;
    
    button{ 
        margin-right: 10px;
        width: 200px;
    }
}

#profile-header {
    .row { margin: 10px 0;}
    .action-wrapper { margin: 0; display: inline;}
    .btn {
        margin: 10px 0 10px 10px;
        width: 200px;
    }
    
    .client-name {
        font-size: 1.75rem;
        line-height: 1.75rem;
        align-self: flex-end;
    }
}
.acc-confirm{ margin-top: 10px;}





