.file-upload-zone {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: rgba(0, 98, 65, 0.21);

    cursor: pointer;
    &:hover {
        background-color: rgba(0, 98, 65, 0.363);
    }
}

.file-upload-wrapper {
    .action-toolbar {
        > * {
            margin-right: 12px;
        }
    }
    p {
        margin: 0; 
        display: block;
    }
}

.file-upload-area {
    button {
        margin-top: 12px;
        width: 100%;
    }

}