.custom-modal.modal {
 background-color: rgba(0,0,0,0.5);
 position: fixed;
 height: 100%;
 width: 100%;
 top: 0;
 left: 0;
 display: flex;
 align-items: center;
 justify-content: center;
 padding: 2rem;

 .modal-content {
   padding: 1rem;
   background-color: white;
   box-shadow: 3px 5px 5px rgba(42, 42, 42, 0.8);
   overflow-y: auto;

   // IE and Edge do not support 'max-content' these two
   // rules essentially do the same thing as 'max-content'.
   width: auto;
   white-space: nowrap;

   max-height: 90%;
   border: 2px solid lightgray;

   .modal-toolbar {
     margin-top: 12px;

     display: flex;
     flex-direction: row;
     flex-wrap: nowrap;
     justify-content: flex-start;
     align-items: center;

     >* {
       margin-right: 8px;
       &:last-child {
         margin-right: 0px;
       }
     }
   }
 }
}

