.client-row {
   padding: 0;
   .client-header {padding-left: 0;}
   .collIcon {margin-top: 6px;}
   .textPos {margin-top: 10px;}
   .marginIcon {margin-top:13px;}
}

.marginBtn {
    width: max-content;
    min-width: 100%;
    .btnText {
        padding: inherit;
    }
}
.table-sm td {padding: 0; .input-group {padding-bottom: 0;}}
.input-group-sm .input-group-prepend .input-group-text {border-radius: 0;}
.containerCell {
    border-width: 2px;
    border-radius: 0;
}
.undoBtn {padding-bottom: 0;}
.allRow {padding-top: 20px;}

.client-checkbox {
    margin-left: 8px;
    margin-right: 8px;
}

#rate-table  {
    margin-bottom: 0;
}

.send-btn-col {
    padding-left: 0;
    padding-right: 0;
    button {
        height: 100%;
        max-height: 42px;
        margin-bottom: 6px;
        min-width: 100%;
    }

    &.no-margin {
        button {
            margin-bottom: 0;
        }
    }
}

.dialog-action-area {
    textarea {
        display: block;
        width: 600px;
        height: 450px;
    }
    .dialog-btns {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        margin-top: 1rem;
        button {
            width: 45%;
        }
    }
}