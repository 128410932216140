.alert-box {
    color: white;
    background-color: black;
    padding: 4px 10px;
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.03);
    font-family: "Open Sans", sans-serif;
    min-width: 30vw;
    box-sizing: border-box;
    line-height: 1rem;
    margin-bottom: 5px;
    box-shadow: 4px 4px 4px gray;
}

.icon {
    display: flex;
    justify-content: center;
    width: 50px;
    padding-right: 20px;
    font-size: 40px;
}

.close-button { 
    padding: 0;
    margin-left: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px;
    color: #ffffff
}
