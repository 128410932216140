.menu-toggle-wrapper {
    height: 100px;
    margin: 0 auto;
    padding-botom: 3rem;
    .close-menu {
        display: block;
        height: 60px;
        font-size: 3rem;
        color: rgba(255, 255, 255, 0.69);
    }

    &:hover {
        .close-menu {
            color: white;
        }
    }
}

.page-title {
    font-weight: bold;
    color: #424242;
    white-space: nowrap;
}

.sbl {
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;

    display: flex;
    flex-row: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

    .sbl-sidebar {
        background-color: #005533;

        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: flex-start;

        cursor: pointer;
        box-sizing: border-box;

        img {
            margin: 0 auto;
            display: block;
            padding: 1rem;
            box-sizing: border-box;
        }
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                .nav-link {
                    box-sizing: border-box;
                    text-underline: none;
                    text-align: center;
                    font-weight: bold;
                    color: rgba(255, 255, 255, 0.69);
                    text-transform: uppercase;
                    white-space: nowrap;

                    padding: 1rem;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;

                    border-top: 1px solid rgb(0, 104, 63);

                    .nav-item-icon {
                        display: block;
                        font-size: 2rem;
                    }

                    &:hover {
                        color: white;
                        background-color: #00492c;
                    }

                    &.current {
                        color: white;
                    }
                }

                &:last-child {
                    .nav-link {
                        border-bottom: 1px solid rgb(0, 104, 63);;
                    }
                }
            }
        }
    }

    .sbl-content-flexwrap {
        flex-grow: 1;
        background-color: rgba(224, 224, 224, 0.5);
        overflow-y: auto;
        overflow-x: auto;

        .sbl-main-content {
            box-sizing: border-box;
            .sbl-header {
                padding: 1rem;
                height: 60px;
                background-color: #f3f3f3;
                display: flex;
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid lightgray;
                box-sizing: border-box;
            }

            .sbl-content {
                padding: 1rem;
                box-sizing: border-box;
                .sbl-welcome-banner {
                    padding: 1rem;
                    background: white;
                }
            }
        }
    }
}

.toolbar {
    margin-left: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    .toolbar-btn {
        display: block;
        color: #424242;
        font-size: 2rem;

        &:hover {
            color: #005533;
        }
    }
}
