#control-tower {

    .control-label {
        margin-bottom: 0;
    }

    .rate-table {
        margin-top: 20px;
        max-width: 600px;
    }
}
