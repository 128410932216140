.widget-layout {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;

    > * {
        margin-right: 12px;
        &:last-child {
            margin-right: 0;
        }
    }
    .widget {
        margin-right: 20px;
        margin-top: 20px;
        table {
            margin-bottom: 0;

            .widget-action-column {
                white-space: nowrap;
            }
        }
    }
}
