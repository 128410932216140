.buy-rate-table-wrapper {
  margin-top: 0.6rem;
  &:last-child {margin-bottom: 0.6rem}
  .table-label {
    margin: 0;
  }

  .gri-table-wrapper {
    height: 300px;
  }

  .table-overflow-wrapper {
    overflow-x: auto;
    margin-top: 0.5rem;
    table {
      position: relative;
      margin-bottom: 0;

      th {
        position: sticky;
        top: 0;
        font-size: 12px;
        white-space: nowrap;
        text-align: center;

        .sort-icon {
          margin-left: 6px;
        }
      }

      tr {
        &.expiring {
          background-color: yellow;
        }

        &.expired {
          background-color: red;
        }
      }
      tr:hover{
        background: lightgray;
      }

      .gri-table{
        padding: 0.35rem;
      }

      .table-td{
        text-align: center;
        .download-btn{
          padding:0;
          line-height: 1;
          font-size: 12px;
        }
      }

      td {
        font-size: 12px;
        max-width: 400px;

        .form-row-action-buttons {
          button {
            font-size: 0.8rem;
            padding: 0 4px;
          }
        }
        .table-row-action-buttons {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;

          button {
            font-size: 0.8rem;
            padding: 0 4px;
            margin-right: 4px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}