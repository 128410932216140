.quote-search-page-wrapper {

  .search-wrapper {
      padding: 1rem;

      background-color: rgba(0, 98, 65, 0.21);
      margin: 1rem 0;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-end;

      .search-form {
      margin-right: 8px;
      }
  }
}