
.table-select{
    margin-top: 25px;
    margin-bottom: 25px;
    width: auto;
}
.cell-error{ border-color: #FF0000; }

.admin-table{
    position: relative;
    overflow: auto;

    .card-body{ 
        padding: 0;
        height: auto;
        overflow: auto;
    }
    button{ 
        border: none;
        background-color: transparent; 
    }
    
    input{ width:100%; }

    .admin-table-title{
        position: sticky;
        top: 0;

        .add-btn{
            color:#006241;
            text-align: center;
        }

        .add-btn[disabled]{ color: #808080; }
    }

    table{
        position: relative;
        margin-bottom: 0;

        .newEntry{
            th{
                top: 50px;
            }
        }
        
        th{ 
            width:33%;
            position: sticky;
            top: 0; 
        }

        .action-cell{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;

            .trash-cancel-btn{ color:#FF0000; }

            .trash-cancel-btn[disabled]{ color: #808080; }
            
            .save-btn{ color:#006241; }

            .save-btn[disabled]{ color: #808080; }
        
            .edit-btn{ color:#FFA500; }

            .edit-btn[disabled]{ color: #808080; }
        }
    }
}

.accessorial-admin-table{
    position: relative;
    overflow: auto;

    .card-body{ 
        padding: 0;
        height: auto;
        overflow: auto;
    }

    button{ 
        border: none;
        background-color: transparent; 
    }
    
    input{ width:100%; }

    .amount-error{ border-color: #FF0000; }
    
    .accessorial-admin-table-title{

        .add-btn{
            color:#006241;
            text-align: center;
        }

        .add-btn[disabled]{ color: #808080; }
    }
    table{
        margin-bottom: 0;
        .newEntry{
            th{
                top: 50px;
            }
        }
        
        th{ 
            width:20%;
            position: sticky;
            top: 0; 
        }

        .action-cell{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;

            .trash-cancel-btn{ color:#FF0000; }

            .trash-cancel-btn[disabled]{ color: #808080; }
            
            .save-btn{ color: #006241; }

            .save-btn[disabled]{ color: #808080; }
        
            .edit-btn{ color:#FFA500; }

            .edit-btn[disabled]{ color: #808080; }
        }
    }
}

.users-admin-table{
    position: relative;
    overflow: auto;
    .card-body { 
        padding: 0;
        height: auto;
        overflow: auto;
    }

    button{ 
        border: none;
        background-color: transparent; 
    }
    
    input{ width:100%; }

    .amount-error{ border-color: #FF0000; }
    
    .users-admin-table-title{
        .add-btn{
            color:#006241;
            text-align: center;
        }

        .add-btn[disabled]{ color: #808080; }
    }
    table{
        margin-bottom: 0;
        th{ 
            width:20%; 
            position: sticky;
            top: 0; 
        }

        .action-cell{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;

            .trash-cancel-btn{ color:#FF0000; }

            .trash-cancel-btn[disabled]{ color: #808080; }
            
            .save-btn{ color:#006241; }

            .save-btn[disabled]{ color: #808080; }
        
            .edit-btn{ color:#FFA500; }

            .edit-btn[disabled]{ color: #808080; }
        }
    }
}

.actions{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
}

.form-actions{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    button {
        width: 45%;
    }
}

.form-errors { color:#FF0000; }

.form-control.has-error { border: 1px solid #FF0000; }

.rate-content-table{
    margin-bottom: 25px;
    .card-body { padding: 0;}
    table{
        margin-bottom: 0;
        .action-cell{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;

            button{ 
                border: none;
                background-color: transparent; 
            }
        
            .edit-btn{ color:#FFA500; }

            .edit-btn[disabled]{ color: #808080; }
        }
    }
}

.rate-content-form{
    margin: 50px 15px;
    .header-group{ padding-left: 0; }
    .line-section{ margin: 5px 0 ;}

    .fa.fa-minus{ 
        color:#FF0000; 
        cursor: pointer;
    }
    .char-counter{
        display: flex;
        justify-content: flex-end;
    }

    .form-action-btns{
        button{ margin: 10px 10px; }
    }

    .new-line-btn{
        color:#006241;
        border: none;
        font-size: x-large;
        background-color: transparent;
    }
}

.port-group-table{

    .card-body { padding:0; }

    .port-header-title{
        .add-btn{
            border: none;
            color:#006241;
            background-color: transparent;
        }
    
        .add-btn[disabled]{ color:#808080; }
    }


    table{
        margin: 0;
        .action-cell{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;

            button{ 
                border: none; 
                background-color: transparent;
            }

            .trash-cancel-btn{ color:#FF0000; }

            .trash-cancel-btn[disabled]{ color: #808080; }
        
            .edit-btn{ color:#FFA500; }

            .edit-btn[disabled]{ color: #808080; }
        }

    }
    .fa.fa-plus{ 
        color: #006241;
        cursor: pointer;
    }

    .port-container{
        margin: 25px 0px;;
        .addUpdate{
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-end;
            button{ margin-left: 10px }
        }
    
        .port-search-wrapper{
            position: relative;
            max-width: 380px;
    
            label, input {
                display: block;
            }
    
            label.has-error {
                color: red;
            }
            .form-control.has-error {
                border: 1px solid red;
    
            }
            ul {
                
                z-index: 90;
                position: absolute;
            
                max-height: 100px;
                min-width: 100%;
            
                padding: 0;
                list-style: none;
                background-color: white;
            
                border-radius: 6px;
                border: 1px solid rgba(220, 220, 220, 0.5);
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
            
                overflow-y: auto;
            
                li {
                  cursor: pointer;
                  display: block;
            
                  padding: 8px;
            
                  border-bottom: 1px solid rgba(220, 220, 220, 0.5);
            
                  &:hover {
                    background-color: rgba(220, 220, 220, 0.5);;
                  }

                  &.highlighted {
                    background-color: rgba(220, 220, 220, 0.5);;
                  }
                }
            }
        }
    }

    }



.port-group {
    width: 75%;
    display: flex;
    flex-flow: row wrap;
    span{ margin-right: 10px;}
    button{
        margin-left: 5px;;
        border: none;
        color: red;
        background-color: transparent;
    }
}

.card.fuel-surcharge{
    width: 50vw;
    button{margin-left: 10px}
}

.align-self-center {
    align-self: center;
}

.other-admin-table{
    position: relative;
    overflow: auto;

    .card-body{ 
        padding: 0;
        height: auto;
        overflow: auto;
    }
    button{ 
        border: none;
        background-color: transparent; 
    }
    
    input{ width:100%; }

    .admin-table-title{
        position: sticky;
        top: 0;

        .add-btn{
            color:#006241;
            text-align: center;
        }

        .add-btn[disabled]{ color: #808080; }
    }

    table{
        position: relative;
        margin-bottom: 0;

        .newEntry{
            th{
                top: 50px;
            }
        }
        
        th{ 
            position: sticky;
            top: 0; 
        }

        .action-cell{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;

            .trash-cancel-btn{ color:#FF0000; }

            .trash-cancel-btn[disabled]{ color: #808080; }
            
            .save-btn{ color:#006241; }

            .save-btn[disabled]{ color: #808080; }
        
            .edit-btn{ color:#FFA500; }

            .edit-btn[disabled]{ color: #808080; }
        }
    }
}